<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="wrap-con">
      <!--banner start-->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
            <img :src="item.banner_path" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <!--banner end-->
      <b-container>
        <b-row>
          <b-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="select-items">
              系列:
              <span :class="series == index ? 'active' : ''" v-for="(item, index) in selectItems" :key="index" @click="selectSeries(item,index)">{{item.NAME}}</span>
            </div>
            <div class="package-list">
              <div class="package-item" v-for="(item, index) in packageItems" :key="index">
                <div class="package-title">{{item.setmeal_name}}</div>
                <div class="package-pic-wrap">
                  <div class="package-pic" @click="toPackageDetail(item)">
                    <img :src="item.team_img" alt="">
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12 changePage">
            <el-pagination
              v-if="totalPage > 0"
              :page-count="totalPage"
              @current-change="changePage"
              background
              layout="prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
            ></el-pagination>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import right from "@/components/Right"; 
  import Swiper from "swiper/swiper-bundle.min";
  import 'swiper/swiper-bundle.min.css';
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      footerNav,
      right
    },
    data() {
      return {
        banner: [],
        selectItems: [],
        series: 2,
        seriesId: "",
        packageItems: [
          // {
          //   title: "优品系列百搭套餐",
          //   img: require("@/../public/img/package-pic-1.png")
          // },
          // {
          //   title: "臻品系列百搭套餐一",
          //   img: require("@/../public/img/package-pic-2.png")
          // },
          // {
          //   title: "臻品系列百搭套餐二",
          //   img: require("@/../public/img/package-pic-1.png")
          // },
          // {
          //   title: "名品系列百搭套餐",
          //   img: require("@/../public/img/package-pic-1.png")
          // }
        ],
        totalPage: 0,
        currentPage: 1,
        keywords: ""
      }
    },
    created() {
      this.keywords = this.$route.query.keyWords;
      this.getBanners();
      this.getBagSetmeals();
      this.getSeriesList();
      this.getBagSetmealsList();
    },
    mounted() {
      
    },
    methods: {
      selectSeries(obj, i){
        if(this.keywords){
          this.keywords = ""
        }
        this.series = i;
        this.seriesId = obj.ID == 3 ? "" : obj.ID;
        this.getBagSetmealsList();
      },
      toPackageDetail(obj){
        dump.link({
          type: 3,
          link: "/bagCheckDetail",
          params: {
            setmeal_id: obj.setmeal_id
          }
        })
      },
      swiper(){
        new Swiper('.swiper-container', {
          autoplay: 5000, //可选选项，自动滑动
          pagination: {
            el: '.swiper-pagination',
            clickable :true
          },
          loop: true,
          paginationClickable: true
        });
      },
      // 获取banner
      getBanners(){
        this.$api.getbanners({banner_type: 19}).then(res => {
          this.banner = res.data;
          this.$nextTick(() => {
            this.swiper();
          })
        }).catch(err => console.error(err))
      },
      //拎包套餐
      getBagSetmeals(){
        this.$api.bagSetmeals({}).then(res => {
          console.log(res)
          this.packageItems = res.data.setmeals;
        }).catch(err => console.error(err))
      },
      // 获取拎包套餐系列
      getSeriesList(){
        this.$api.seriesList({}).then(res => {
          this.selectItems = res.data;
        }).catch(err => console.error(err))
      },
      // 获取拎包套餐列表
      getBagSetmealsList(){
        this.$api.bagSetmealsList({series_id: this.seriesId, currentPage: this.currentPage, keywords: this.keywords}).then(res => {
          this.packageItems = res.data.setmeals;
          this.totalPage = res.data.totalPage;
        }).catch(err => console.error(err))
      },
      // 分页
      changePage(page){
        this.currentPage = page;
        this.getBagSetmealsList();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "bagCheck"
</style>
